/* eslint-disable no-param-reassign */
import { gql, stringifyVariables } from 'urql';
import { WithTypename, Variables } from '@urql/exchange-graphcache';
import { relayPagination } from '@urql/exchange-graphcache/extras';

import {
  GraphCacheConfig,
  GraphCacheUpdaters,
  OutputCombinationListModel,
  PreviewStatus,
  PreviewsListModel,
  TenantModel,
} from '../../schema.types';
import introspection from '../introspection.json';
import {
  CalcHasCurrentPage,
  simplePaginationPatched,
} from '../simplePaginationPatched';
import {
  CacheBrands,
  CacheBrandsBrand,
  CacheBrandsDocument,
  CacheGenres,
  CacheGenresDocument,
  CacheGenresGenre,
  CacheProductGroups,
  CacheProductGroupsDocument,
  CacheProductGroupsProductGroup,
  CacheTenants,
  CacheTenantsDocument,
  CacheTenantsTenant,
  CacheUserProfile,
  CacheUserProfileVariables,
  CacheUserProvisioningTable,
  CacheUserProvisioningTables,
  CacheUserProvisioningTablesDocument,
  _RefiredPreview,
} from './cacheExchangeOptions.urql.generated';
import {
  outputCombinationModelUpdaters,
  invalidateAllOutputCombinations,
} from './outputCombinations';
import { KEYS } from './keys';
import { indicateCacheMiss, invalidatePaginatedQuery } from './shared';
import { updateOnOrder } from './orders';
import {
  invalidateAllPreviewsExceptQuery,
  WithPreviewsQueryArgs,
} from './previews';
import { invalidateProductOptionsByProductGroupId } from './productGroups';

/*
  Convention: we prefix fragment names with _ to separate fragments in the cache config and the rest of the app. This helps to avoid fragment name collisions and allows us not to care about fragment names in the rest of the app.
*/

export type WithRunBeforeUpdater<T> = T & {
  runBeforeUpdater?: () => void;
};

type VariablesWithRunBeforeUpdater = WithRunBeforeUpdater<Variables>;

export { type WithPreviewsQueryArgs };

export const cacheExchangeOptions: Omit<GraphCacheConfig, 'storage'> = {
  schema: introspection,
  keys: KEYS,
  resolvers: {
    AdvancedProductFilterOptions: {
      metadataFilterOptions: relayPagination(),
    },
    MetadataKeyFilterOption: {
      values: relayPagination(),
    },
    MyOrderListModel: {
      myOrders: simplePaginationPatched(),
    },
    TemplateListModel: {
      templates: simplePaginationPatched(),
    },
    ProductListModel: {
      products: simplePaginationPatched(),
    },
    ContentKitsModel: {
      contentKits: simplePaginationPatched(),
    },
    ShapeCollectionListModel: {
      shapeCollections: simplePaginationPatched(),
    },
    ArtworkSetListModel: {
      artworkSets: simplePaginationPatched(),
    },
    OutputCombinationGroupListModel: {
      items: simplePaginationPatched(),
    },
    OutputCombinationListModel: {
      items: simplePaginationPatched(),
    },
    CompatibleProductSearchResult: {
      products: simplePaginationPatched({ firstPaginationPageNumber: 0 }),
    },
    PreviewsListModel: {
      previews: (parent, args, cache, info) => {
        /*
          This is part of the logic that underpins UX in the ProductPreviews and TemplatePreviews.
          In those views we have:
          - offset-based pagination
          - an ability to remove a preview from the paginated list

          The UX: when a preview is removed we re-request the whole so-far-loaded list to preserve scroll position
          and prevent the list from jumping around.
          Here is what happens when removing a preview in cache:
          Before:
          previews(page: 1, pageSize: 20)
          previews(page: 2, pageSize: 20)
          previews(page: 3, pageSize: 20)
          After removing a preview:
          previews(page: 1, pageSize: 20) (*)
          previews(page: 2, pageSize: 20) (*)
          previews(page: 3, pageSize: 20) (*)
          previews(page: 1, pageSize: 60) (**)

          After the list gets refetched we invalidate (see updates -> PreviewsListModel -> previews)
          all (except for the new data (**)) previously loaded chunks of data (*)
          because they contain a link / pointer to the removed preview and contain stale data about the order of previews 
          (after removing a record in offset-based pagination the data naturally gets shifted starting from the removed item
          and the old chunks of data don't account for that shift hence the order there is stale).

          The calcHasCurrentPage logic is needed to make simplePaginationPatched read data from (**) because its default logic for hasCurrentPage isn't smart enough.

          invalidateAllPreviewsExceptQuery is used to invalidate everything
          except for the query on the screen (to preserve scroll position) because the query on the screen
          will be updated / refetched manually in the process above via the refetch fn + network-only policy.

        */
        const calcHasCurrentPage: CalcHasCurrentPage = (
          requestedRange,
          recordsInCacheNumber,
        ) =>
          requestedRange.skip + requestedRange.limit <= recordsInCacheNumber ||
          recordsInCacheNumber === parent.filteredTotal;

        return simplePaginationPatched({ calcHasCurrentPage })(
          parent,
          args,
          cache,
          info,
        );
      },
    },
    Query: {
      metadataFilterOptions: (_, args) => ({
        __typename: 'MetadataKeyFilterOption',
        id: args.metadataKeyFilterId,
      }),
      allOutputCombinationsByFilters: relayPagination(),
      templateById: (_, args) => ({
        __typename: 'TemplateModel',
        id: args.id,
      }),
      outputCombinationById: (_, args) => ({
        __typename: 'OutputCombinationModel',
        id: args.id,
      }),
      productById: (_, args) => ({
        __typename: 'ProductModel',
        id: args.id,
      }),
      contentKitById: (_, args) => ({
        __typename: 'ContentKitModel',
        id: args.id,
      }),
      shapeCollectionById: (_, args) => ({
        __typename: 'ShapeCollectionModel',
        id: args.id,
      }),
      artworkSetById: (_, args) => ({
        __typename: 'ArtworkSetModel',
        id: args.id,
      }),
      productGroupById: (_, args) => ({
        __typename: 'ProductGroupModel',
        id: args.productGroupId,
      }),
      genreById: (_, args) => ({
        __typename: 'GenreModel',
        id: args.id,
      }),
      outputCombinationGroup: (_, args, cache) => {
        const productOutputCombinationGroupKey = cache.keyOfEntity({
          __typename: 'ProductOutputCombinationGroupModel',
          id: args.groupId,
        }) as string;

        const contentKitOutputCombinationGroupModelKey = cache.keyOfEntity({
          __typename: 'ContentKitOutputCombinationGroupModel',
          id: args.groupId,
        }) as string;

        const resolveToProductVersion = cache.resolve(
          productOutputCombinationGroupKey,
          'id',
        );
        const resolveToContentKitVersion = cache.resolve(
          contentKitOutputCombinationGroupModelKey,
          'id',
        );

        if (resolveToProductVersion) {
          return productOutputCombinationGroupKey;
        }
        if (resolveToContentKitVersion) {
          return contentKitOutputCombinationGroupModelKey;
        }
        return indicateCacheMiss();
      },
    },
    ProductModel: {
      name: (parent, _, cache, info) => {
        const alternativeValue = cache.resolve(
          cache.keyOfEntity({
            id: parent.id as string,
            __typename: 'SimplifiedProductModel',
          }),
          info.fieldName,
        );
        return (parent[info.fieldName as 'name'] ?? alternativeValue) as string;
      },
    },
  },
  updates: {
    PreviewsListModel: {
      previews: (_: WithTypename<PreviewsListModel>, args, cache, info) => {
        if (args.page === 1) {
          const itemsFields = cache
            .inspectFields(info.parentKey)
            .filter((x) => x.fieldName === 'previews');
          itemsFields.forEach((x) => {
            if (stringifyVariables(x.arguments) !== stringifyVariables(args)) {
              cache.invalidate(info.parentKey, x.fieldKey);
            }
          });
        }
      },
    },
    OutputCombinationListModel: {
      items: (
        _: WithTypename<OutputCombinationListModel>,
        args,
        cache,
        info,
      ) => {
        /*
          Use case: we want to change sorting within an OutputCombinationListModel (IS horizontal row) and want to keep the current IS state (vertical and horizontal rows) in place (with the same ids since overall IS context doesn't change and the row with new sorting stays the same conceptually - the same collection but with a different ordering) without refetching it.

          With the current schema there is no way to keep data with different sorting cached since on this level the client only knows about pagination options and nothing about sorting, as a result it's impossible to differentiate as it could've been done if the sorting was specifiable.
            items(page: 1, sortA)
            items(page: 1, sortB)
            items(page: 2, sortA)

          At the moment the client knows only pagination options and caches data in the following way:
            items(page: 1)
            items(page: 2)
            items(page: 3)
          So here we employ the following logic: when sorting withing a row changes we re-request the row from scratch (with page 0 / 1 depending on the query) and invalidate all other possibly existing cached pages (2, 3, etc.) to avoid the situation where they are reused as in [new page 1 combinations with new sorting, old page 2 combinations, old page 3 combinations].

          If we want the data with different sorting to be cached and then read from the cache properly we need to have the backend to allow passing sorting options on the items level*, this way URQL will keep loaded data with different sorting separately and we'll be able to read it from cache without having to use network-only policy.

          * which even conceptually makes more sense - we put the variables and the data they affect as close as possible to each other and not somewhere on the top level where it could be impossible to read them without extra hacks.

          Note that updates.OutputCombinationListModel.items is only being called when items are being re-fetched (by passing 'network-only' request policy) and is not called when resolver finds data in cache.
        */
        if (args.page === 1) {
          const itemsFields = cache
            .inspectFields(info.parentKey)
            .filter((x) => x.fieldName === 'items');
          itemsFields.forEach((x) => {
            if ((x.arguments as { page: number }).page > 1) {
              cache.invalidate(info.parentKey, x.fieldKey);
            }
          });
        }
      },
    } satisfies GraphCacheUpdaters['OutputCombinationListModel'],
    OutputCombinationModel: outputCombinationModelUpdaters,
    Mutation: {
      inviteUser: (_, __, cache) => {
        cache
          .inspectFields('Query')
          .filter((x) => x.fieldName === 'users')
          .forEach((field) => cache.invalidate('Query', field.fieldName));
      },
      welcomeMessageShown: (_, __, cache) => {
        cache.updateQuery<CacheUserProfile, CacheUserProfileVariables>(
          {
            query: gql`
              query CacheUserProfile {
                userProfile {
                  welcomeMessageShown
                }
              }
            `,
          },
          (x) => {
            if (x) {
              x.userProfile.welcomeMessageShown = true;
            }
            return x;
          },
        );
      },
      order: (parent, { budgetId }, cache) => {
        updateOnOrder({
          cache,
          budgetId,
          orderItems: parent.order.orderItems,
          orderCost: parent.order.totalCost ?? 0,
          wasOrderedWithPremium: false,
        });
      },
      orderPremium: (parent, { budgetId }, cache) => {
        updateOnOrder({
          cache,
          budgetId,
          orderItems: parent.orderPremium.orderItems,
          orderCost: parent.orderPremium.totalCost ?? 0,
          wasOrderedWithPremium: true,
        });
      },
      addTemplate: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'templates');
      },
      removeTemplate: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'templates');
      },
      updateTemplate: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'templates');
      },
      addShapeCollection: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'shapeCollections');
      },
      removeShapeCollection: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'shapeCollections');
      },
      updateShapeCollection: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'shapeCollections');
      },
      addProduct: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'products');
        // Adding a new product or content-kit creates more images that are available through
        // products x content-kits combination, so when a new product/content-kit is added
        // the so-far loaded output combinations get stale because they don't contain all images.
        // The same goes for updating, because updating may change visual aspects of end images.
        // Because of that we have to purge and refresh the cache here.
        invalidateAllOutputCombinations(cache);
      },
      removeProduct: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'products');
        invalidateAllOutputCombinations(cache);
      },
      updateProduct: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'products');
        invalidateAllOutputCombinations(cache);
      },
      addContentKit: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'contentKits');
        // Adding a new product or content-kit creates more images that are available through
        // products x content-kits combination, so when a new product/content-kit is added
        // the so-far loaded output combinations get stale because they don't contain all images.
        // The same goes for updating, because updating may change visual aspects of end images.
        // Because of that we have to purge and refresh the cache here.
        invalidateAllOutputCombinations(cache);
      },
      deleteContentKit: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'contentKits');
        invalidateAllOutputCombinations(cache);
      },
      updateContentKit: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'contentKits');
        invalidateAllOutputCombinations(cache);
      },
      addArtworkSet: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'artworkSets');
      },
      removeArtworkSet: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'artworkSets');
      },
      updateArtworkSet: (_, __, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidatePaginatedQuery(cache, 'artworkSets');
      },
      requestPreviews: (_, __, cache, info) => {
        const variables = info.variables as WithPreviewsQueryArgs<Variables>;
        invalidateAllPreviewsExceptQuery(cache, variables);
      },
      removePreview: (_, __, cache, info) => {
        const variables = info.variables as WithPreviewsQueryArgs<Variables>;

        invalidateAllPreviewsExceptQuery(cache, variables);
      },
      refirePreviewRequest: (_, args, cache) => {
        const fragment = gql`
          fragment _RefiredPreview on PreviewModel {
            id
            failureReason
            status
          }
        `;
        args.previewIds.forEach((id) =>
          cache.writeFragment<_RefiredPreview>(fragment, {
            id,
            failureReason: null,
            status: PreviewStatus.Processing,
          }),
        );
      },
      updateProductGroup: (_, args, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        invalidateProductOptionsByProductGroupId({
          cache,
          productGroupId: args.productGroupId,
        });
      },
      addProductGroup: (parent, _, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        cache.updateQuery<CacheProductGroups>(
          {
            query: CacheProductGroupsDocument,
          },
          (cacheProductGroups) => {
            if (cacheProductGroups) {
              cacheProductGroups.productGroups =
                cacheProductGroups.productGroups.concat(
                  parent.addProductGroup as NonNullable<CacheProductGroupsProductGroup>,
                );
            }
            return cacheProductGroups;
          },
        );
      },
      removeProductGroup: (_, args, cache, info) => {
        const variables = info.variables as VariablesWithRunBeforeUpdater;
        variables.runBeforeUpdater?.();

        cache.updateQuery<CacheProductGroups>(
          {
            query: CacheProductGroupsDocument,
          },
          (cacheProductGroups) => {
            if (cacheProductGroups) {
              cacheProductGroups.productGroups =
                cacheProductGroups.productGroups.filter(
                  (x) => x.id !== args.productGroupId,
                );
            }
            return cacheProductGroups;
          },
        );

        invalidateProductOptionsByProductGroupId({
          cache,
          productGroupId: args.productGroupId,
        });
      },
      addGenre: (parent, _, cache) => {
        cache.updateQuery<CacheGenres>(
          {
            query: CacheGenresDocument,
          },
          (genres) => {
            if (genres) {
              genres.genres = genres.genres.concat(
                parent.addGenre as NonNullable<CacheGenresGenre>,
              );
            }
            return genres;
          },
        );
      },
      deleteGenre: (_, args, cache) => {
        cache.updateQuery<CacheGenres>(
          {
            query: CacheGenresDocument,
          },
          (genres) => {
            if (genres) {
              genres.genres = genres.genres.filter((x) => x.id !== args.id);
            }
            return genres;
          },
        );
      },
      addBrand: (parent, _, cache) => {
        cache.updateQuery<CacheBrands>(
          {
            query: CacheBrandsDocument,
          },
          (brands) => {
            if (brands) {
              brands.brands = brands.brands.concat(
                parent.addBrand as NonNullable<CacheBrandsBrand>,
              );
            }
            return brands;
          },
        );
      },
      deleteBrand: (_, args, cache) => {
        cache.updateQuery<CacheBrands>(
          {
            query: CacheBrandsDocument,
          },
          (brands) => {
            if (brands) {
              brands.brands = brands.brands.filter((x) => x.id !== args.id);
            }
            return brands;
          },
        );
      },
      addTenant: (parent, _, cache) => {
        cache.updateQuery<CacheTenants>(
          {
            query: CacheTenantsDocument,
          },
          (tenants) => {
            if (tenants) {
              tenants.managedTenants = tenants.managedTenants.concat(
                parent.addTenant as NonNullable<CacheTenantsTenant>,
              );
            }
            return tenants;
          },
        );
      },
      removeTenant: (_, args, cache) => {
        cache.updateQuery<CacheTenants>(
          {
            query: CacheTenantsDocument,
          },
          (tenants) => {
            if (tenants) {
              tenants.managedTenants = tenants.managedTenants.filter(
                (x) => x.id !== args.id,
              );
            }
            return tenants;
          },
        );
      },
      addUserProvisioningTable: (parent, _, cache) => {
        cache.updateQuery<CacheUserProvisioningTables>(
          {
            query: CacheUserProvisioningTablesDocument,
          },
          (tables) => {
            if (
              tables &&
              parent.addUserProvisioningTable.__typename ===
                'AddUserProvisioningTableSucceeded'
            ) {
              tables.userProvisioningTables =
                tables.userProvisioningTables.concat(
                  parent.addUserProvisioningTable
                    .data as NonNullable<CacheUserProvisioningTable>,
                );
            }
            return tables;
          },
        );
      },
      removeUserProvisioningTable: (_, args, cache) => {
        cache.updateQuery<CacheUserProvisioningTables>(
          {
            query: CacheUserProvisioningTablesDocument,
          },
          (tables) => {
            if (tables) {
              tables.userProvisioningTables =
                tables.userProvisioningTables.filter((x) => x.id !== args.id);
            }
            return tables;
          },
        );
      },
      setTenantProjectManagers: (_, args, cache) => {
        const tenantEntity = cache.keyOfEntity({
          __typename: 'TenantModel',
          id: args.tenantId,
        }) as string;

        const projectManagersKey: keyof TenantModel = 'projectManagers';
        cache.invalidate(tenantEntity, projectManagersKey);
      },
      deleteOrder: (_, __, cache) => {
        invalidateAllOutputCombinations(cache);
      },
    },
  },
};
